import { WorkScheduleEligibilityStepInterface } from '@src/interfaces/workSchedule'
import { workScheduleEligibilityCustomFiltersRequests } from '@src/api/workSchedule'
import { IdAndName } from '@src/interfaces'
import { TableFilter } from '@src/features/AudienceSelection/AudienceTable'

export const handleCustomFilters = async (
  values: WorkScheduleEligibilityStepInterface,
  customFilters: TableFilter,
) => {
  if (values.eligibility_group_type?.id !== 'employee_filters' || !customFilters) {
    return
  }
  let groupId

  if (!values.custom_filters_eligibility_group?.id) {
    const { data: newFilter } = await workScheduleEligibilityCustomFiltersRequests.submit(
      { table_filters: customFilters },
      values.id,
    )
    groupId = newFilter.id
    values.custom_filters_eligibility_group = newFilter
  } else {
    await workScheduleEligibilityCustomFiltersRequests.update(
      { table_filters: customFilters },
      values.id,
      values.custom_filters_eligibility_group.id,
    )
    groupId = values.custom_filters_eligibility_group.id
  }
  // On the final step the eligible employees are fetched by the group.id,
  // even if the source is custom filter, so to make it display correct results,
  // we need to replace the value here before proceeding to the next step
  values.custom_filters_eligibility_group = { id: groupId } as IdAndName
}
