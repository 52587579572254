import React from 'react'
import { VStack } from '@revolut/ui-kit'

import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { LapeRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useQuery } from '@src/utils/queryParamsHooks'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { PageActions } from '@components/Page/PageActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { WorkScheduleApprovalsStepInterface } from '@src/interfaces/workSchedule'

import { CommonTabsProps } from '../common'
import { ApprovalChainInput } from './ApprovalChainInput'

type Props = CommonTabsProps
export const WorkScheduleApprovals = ({ nextTabPath }: Props) => {
  const { query } = useQuery()
  const { values } = useLapeContext<WorkScheduleApprovalsStepInterface>()

  return (
    <>
      <VStack space="s-16">
        <VStack>
          <SectionTitle title="Should overtime & night shifts require approval when requested by employees?" />
          <LapeRadioSwitch<boolean>
            name="require_approval_for_overtime_and_night_shifts"
            variant="horizontal"
            options={[
              {
                id: 'yes',
                label: 'Yes',
                description: 'Every request should be approved by someone',
                value: true,
              },
              {
                id: 'no',
                label: 'No',
                description: 'Requests are automatically approved at all times',
                value: false,
              },
            ]}
          />
        </VStack>
        {values.require_approval_for_overtime_and_night_shifts && <ApprovalChainInput />}
      </VStack>
      <PageActions pt="s-64">
        <NewSaveButtonWithPopup
          noPopup
          useValidator
          hideWhenNoChanges={false}
          onAfterSubmit={res => {
            navigateReplace(pathToUrl(nextTabPath, { id: res.id }, query))
          }}
        >
          Next
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}
